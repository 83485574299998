<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2 v-if="model.id">{{ model.name }}</h2>
        <h2 v-else>Novo Formulário</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do formulário</legend>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 100px; min-height: 32px">
            <erp-s-field view="ll" :label="!model.active ? 'Desativado' : 'Ativo'">
              <sl-checkbox no-yes-no-val v-model="model.active" id="active"/>
            </erp-s-field>
          </e-col>
          <!--          <e-col class="flex items-center justify-end" style="max-width: 120px; min-height: 32px">
                      <erp-s-field view="ll" label="Público"
                                   icon-help="Marcar como público libera o acesso ao projeto para qualquer pessoa">
                        <sl-checkbox no-yes-no-val v-model="model.public" id="active"/>
                      </erp-s-field>
                    </e-col>-->
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Nome">
                  <erp-input placeholder="Defina um nome para o formulário" v-model="model.name" required autofocus/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 300px">
                <erp-s-field label="Código">
                  <erp-input placeholder="Defina um código único para o formulário" v-model="model.code"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Categoria">
                  <form-category-select v-model="model.category"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field label="Ordem">
                  <erp-input placeholder="Posição do formulário" v-model="model.order"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição">
                  <textarea placeholder="Descrição sobre o formulário" v-model="model.description" class="sl-textarea"
                            rows="3"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Folha de Estilo (Avançado)</legend>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <code-editor v-model="model.stylesheet" :languages="[['css']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="max-width: 200px">
            <erp-s-field label="Nome da Entidade no Sistema">
              <erp-input v-model="model.entityName"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field label="ID da Entidade no Sistema">
              <erp-input v-model="model.entityId"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Formulário" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
        <!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
                  <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                             self="top right" :offset="[0, 0]">
                    <div>
                      <ul>
                        <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                        <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
                      </ul>
                    </div>
                  </u-popover>
                </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import {newForm, updateForm, findForm} from "@/domain/form/services"
import mock from "@/domain/form/helpers/formModel"
import SlCheckbox from "@/reuse/input/Checkbox"
import FormCategorySelect from "components/forms/components/include/CategorySelect"
import CodeEditor from "simple-code-editor"

export default {
  name: 'FormularioWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {},
  components: {
    FormCategorySelect,
    // ErpSelect,
    // PersonSelect,
    SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent,
    CodeEditor
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      model: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {},
  methods: {
    load() {
      this.loading = true
      findForm(this.id || this.model.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.model.id = response.data.id
            }
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))
      /*const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })*/

      const relacoes = ['category']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      data.config = JSON.stringify(data.config)

      return data
    },
    prepareToEdit(data) {
      /*const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })*/
      /*const datas = ['dataCompetencia', 'dataVencimento', 'dataPagamento']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })*/
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newForm
        if (this.model.id) {
          method = updateForm
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.model.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Formulário com ID #${response.data.id} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Novo Formulário com ID #${response.data.id} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              this.preventBind = true
              this.load()
              this.$nextTick(() => {
                this.preventBind = false
              })
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    }
  }
}
</script>
